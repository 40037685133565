import React, { useState } from 'react';

const ApisContext = React.createContext([ {}, () => {} ]);

const ApisProvider = props => {
    
    const [apis, setApis ] = useState([]);

    return (
        <ApisContext.Provider value={[apis, setApis]}>
            {props.children}
        </ApisContext.Provider> 
    );
}

export { ApisContext, ApisProvider };