import React, { useEffect, useState, useContext } from "react";
import { Button, Input, Select, Tooltip, notification } from "antd";
import { ArrowRightOutlined, FileExcelOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import urlExist from "url-exist";
import ReactExport from "react-export-excel";
import { Link } from "react-router-dom";
import { LoadingScreen } from "./loading/LoadingScreen";
import fetchSinToken from "../helpers/fetch";
import { TableApis } from "./TableApis";
import { ApisContext } from "../context/ApisContext";

const { Option } = Select;

const usoApi = [
  { label: "Integraciones", value: 1 },
  { label: "Wuala - PrestaShop", value: 2 },
  { label: "Wuala - Mercado Libre", value: 3 },
  { label: "App Soporte", value: 4 },
  { label: "Integración E-Commerce Externo", value: 5 },
  { label: "App ctacteweb", value: 6 },
  { label: "Integracion soft. 3°", value: 7 },
  { label: "Wuala - APIS Server Cliente", value: 8 },
  
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const Home = () => {
  const [apis, setApis] = useContext(ApisContext);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [ showPanel, setShowPanel ] = useState(false);


  useEffect(() => {
    const getApis = async () => {
      try {
        const resp = await fetchSinToken("apis");
        const data = await resp.json();
        if (data.ok) {
          setApis(data.apis);
          setDataFilter(data.apis);
          setLoading(false);
        } else {
          setLoading(false);
          notification['error']({
            message: 'Error',
            description: data.message
          });
        }
      } catch (error) {
        console.log(error.message);
        setLoading(false);
        notification['error']({
          message: 'Fallo la conexión al servidor',
        });
      }
    };

    getApis();
    setReload(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  //Intervalo de 10 minutos para informar las apis que están caídas
  useEffect(() => {
    const interval = setInterval(() => {
      sendMsg();
    }, 600000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apis]);


  // Notificar apis caidas por Telegram y Correo
  const sendMsg = async () => {
    let msgErrorGmail = "";
    let msgErrorTelegram = "";

    for (const api of apis) {
      const activa = await urlExist(api.url);
      if (!activa && api.notificar) {
        msgErrorGmail = `${msgErrorGmail} <li>${api.nombre} - ${api.url}</li>`;
        msgErrorTelegram = `${msgErrorTelegram} \n - ${api.nombre} (${api.url})`;
      }
    }

    // Envio de mensaje por telegram
    try {
      if (msgErrorTelegram) {
        await fetchSinToken(
          "send-msg-to-telegram",
          { mensaje: msgErrorTelegram },
          "POST"
        );
      }
    } catch (error) {
      console.log(error.message);
    }

    // Envio de mail
    try {
      if (msgErrorGmail) {
        await fetchSinToken("send-mail", { apis: msgErrorGmail }, "POST");
      }
    } catch (error) {
      console.log(error.message);
    }

   };



  const onChangeEmpresa = (value) => {
    if(!value) return;

    let resultadosBusqueda = apis.filter((api) => {
      if (api.nombre.includes(value)) {
        return api;
      }
      return null;
    });
    setDataFilter(resultadosBusqueda);

  }

  const filterUso = () => {
    if (selectedItems.length < 1) {
      setDataFilter(apis);
      return;
    }

    let resultadosBusqueda = apis.filter((api) => {
      if (selectedItems.includes(api.uso_api)) {
        return api;
      }
      return null;
    });
    setDataFilter(resultadosBusqueda);
  };


  const onChangeText = (e) => {
    if(dataFilter.length < 1){
      return;
    }

    let resultadosBusqueda= apis.filter((api) => {
      if(api.codigo.toString().toLowerCase().includes(e.toLowerCase())
      || ( api.comentarios && api.comentarios.toLowerCase().includes(e.toLowerCase()))
      ){
        return api; 
      }
      return null;
    });
    setDataFilter(resultadosBusqueda);

  }


  const onChangeDaysLeft = (value) => {
    if(!value) return;

    let resultadosBusqueda = apis.filter((api) => {
      if (api.dias_restantes <= value) {
        return api;
      }
      return null;
    });
    setDataFilter(resultadosBusqueda);
  }


  return (
    <div className="header">
      <p className="title">APIS</p>

      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="button-add">
            <Button
              size="large"
              shape="circle"
              onClick={() => setShowPanel(!showPanel)}
            >
              {showPanel ? <CloseOutlined /> : <SearchOutlined />}
            </Button>
            <Link to="/formulario">
              <Button type="primary" shape="round">
                Agregar API <ArrowRightOutlined />
              </Button>
            </Link>
          </div>

          {showPanel && (
            <div className="space">
              <Select
                showSearch
                allowClear
                placeholder="Empresa"
                optionFilterProp="children"
                style={{ width: "250px" }}
                onChange={onChangeEmpresa}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {apis.map(({ nombre }) => (
                  <Option key={nombre} value={nombre}>
                    {nombre}
                  </Option>
                ))}
              </Select>

              <Select
                onChange={onChangeDaysLeft}
                placeholder="Días"
                style={{ width: 120 }}
                allowClear
              >
                <Option value={10}>{"<=10"}</Option>
              </Select>

              <Input
                placeholder="Código o comentario"
                allowClear
                style={{ width: "300px" }}
                onChange={(e) => onChangeText(e.target.value)}
                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
              />

              <Select
                mode="multiple"
                showArrow
                showSearch={false}
                options={usoApi}
                placeholder="uso API"
                value={selectedItems}
                onChange={(uso_api) => setSelectedItems(uso_api)}
                style={{ width: "250px" }}
                maxTagCount={"responsive"}
              />

              <div>
                <Button
                  type="dashed"
                  onClick={filterUso}
                  style={{ marginRight: "15px" }}
                >
                  Filtrar
                </Button>

                <ExcelFile
                  element={
                    <Tooltip title="Exportar a Excel">
                      <Button ghost>
                        <FileExcelOutlined />
                      </Button>
                    </Tooltip>
                  }
                  filename="APIS"
                >
                  <ExcelSheet data={dataFilter} name="apis">
                    <ExcelColumn
                      label="Código"
                      font={{ bold: true }}
                      value="codigo"
                    />
                    <ExcelColumn label="Empresa" value="nombre" />
                    <ExcelColumn label="Direccion URL" value="url" />
                    <ExcelColumn label="Puerto" value="puerto" />
                    <ExcelColumn label="Días restantes" value="days" />
                    <ExcelColumn
                      label="Email Licencias"
                      value="email_licencias"
                    />
                    <ExcelColumn label="Cuit" value="cuit" />
                    <ExcelColumn label="Ip Datacenter" value="ip_datacenter" />
                    <ExcelColumn
                      label="Lista de Precios"
                      value="lista_precios"
                    />
                    <ExcelColumn
                      label="Uso API"
                      value={(col) => {
                        if (col.uso_api) {
                          return usoApi[col.uso_api - 1].label;
                        }
                      }}
                    />
                    <ExcelColumn label="Comentarios" value="comentarios" />
                  </ExcelSheet>
                </ExcelFile>
              </div>
            </div>
          )}

          <TableApis
            data={dataFilter}
            setReload={setReload}
            pagination={{
              defaultPageSize: 10,
              defaultCurrent: 1,
            }}
          />
        </>
      )}
    </div>
  );
};

export default Home;
