import React from 'react';
import { Button } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import notFound from '../assets/404-Not-Found.svg';

export const NotFound = () => {
    return (

        <div className="flex-404">
            <img src={notFound} alt="404 page not found" width="50%" />
            <Link to="/"> 
            <Button type="primary" danger > <RollbackOutlined /> Regresar a la página principal</Button>
            </Link>
        </div>

    )
}
