import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./components/Home";
import FormAdd  from "./components/FormAdd";
import { NotFound } from './components/NotFound';
import { ApisContext, ApisProvider } from "./context/ApisContext";

function App() {
  const [apis, setApis] = useContext(ApisContext);

  return (
    <BrowserRouter>
      <ApisProvider value={[apis, setApis]}>
        <Routes>
          <Route exact path="/" element={ <Home /> } />      
          <Route path="/formulario/:id" element={ <FormAdd /> } />
          <Route path="/formulario" element={ <FormAdd /> } />
          <Route path="*" element={ <NotFound /> } />
        </Routes>
      </ApisProvider>
    </BrowserRouter>
  )
}

export default App;
