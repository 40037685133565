import React, { useState, useEffect } from "react";
import { Button } from "antd";
import {  CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Pulse } from "./loading/Pulse";
import urlExist from 'url-exist';



const ApiState = ({ url, notificar }) => {
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkApis();
  // Intervalo de 8 minutos para verificar el estado de las apis
  const timer = setInterval(() => {
    setLoading(true);
    checkApis();
  }, 1000 * 60 * 8);
  
    return () => {
      clearInterval(timer);
    }
    // eslint-disable-next-line
  }, []);



  // Comprobar el estado de las Apis
  const checkApis = async() => {

   const res = await urlExist(url);
   if (res) setError(false);  
   return setLoading(false);
  }


  return (   
        <>
          {loading ? <Pulse /> : <StateApi state={error} notificar={notificar} />}
        </>
  );
};



const StateApi = (props) => {
  const { state, notificar } = props;
  let classNameSuccess = {
    background: "green", borderColor: "green", cursor: "auto"
  };
  let classNameError = {
    background: "red", borderColor: "red", cursor: "auto"
  };
  if (!notificar) {
    classNameSuccess = {...classNameSuccess, outline: '5px solid yellow'};
    classNameError = {...classNameError, outline: '5px solid yellow'};
  }


  return (
    <>
      {state ? (
        <Button
          type="primary"
          icon={<CloseOutlined />}
          style={classNameError}
          shape="circle"
          size="large"
        ></Button>
      ) : (
        <Button
          type="primary"
          style={classNameSuccess}
          icon={<CheckOutlined />}
          shape="circle"
          size="large"
        ></Button>
      )}
    </>
  );
};

export default ApiState;
