import React from 'react';

import './loadingScreen.css';

export const LoadingScreen = () => {
    return (
        <div className="wrapper">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <span>Cargando</span>
    </div>
    )
}
