import React from 'react';
import { Table, Tooltip, Button, notification, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import fetchSinToken from '../helpers/fetch';
import ApiState from './ApiState';


const usoApis = [ '', 'Integraciones', 'Wuala - PrestaShop', 'Wuala - Mercado Libre', 'App Soporte', 'Integración E-Commerce Externo',
'App ctacteweb', 'Integracion soft. 3°','Wuala - APIS Server Cliente'];

export const TableApis = ({ data, setReload }) => {

    const columns = [
        { title: 'Código', dataIndex: 'codigo', align: 'center', key: 'codigo' },
        { title: 'Empresa', dataIndex: 'nombre', align: 'center', key: 'nombre' },
        { title: 'URL', dataIndex: 'url', key: 'url' },
        { title: 'Puerto', dataIndex: 'puerto', align: 'center', key: 'port' },
        { title: 'Días restantes', dataIndex: 'dias_restantes', align: 'center', key: 'days'},
        { title: 'Estado', key: 'state', width: 100, render: (record) =>
        <ApiState notificar={record.notificar} url={record.url} />
        },
        { title: 'Acciones', key: 'operation', render: (record) => 
        <>
        <Tooltip title="Editar">
            <Link to={`/formulario/${record._id}`}>
            <Button
            type="primary"
            style={{ margin: "5px" }}
            icon={<EditOutlined />}
            ></Button>
            </Link>
        </Tooltip>

        <Popconfirm
            title="¿Está seguro que desea eliminarla?"
            onConfirm={() => deleteApi(record._id)}
            okText="Si"
            cancelText="No"
        >
            <Button
            type="primary"
            style={{ margin: "10px" }}
            icon={<DeleteOutlined />}
            danger
            ></Button>
        </Popconfirm>

        </>},
    ];


    const expandedRowRender = (record) => {
        const columns = [
          { title: 'Lista Precios', dataIndex: 'lista_precios', key: 'lista_precios' },
          { title: 'Uso API', dataIndex: 'uso_api', key: 'uso_api', render: record =>
           <i>{usoApis[record]}</i>
           },
          { title: 'Email Licencias', dataIndex: 'email_licencias', key: 'email_licencias' },
          { title: 'Cuit', dataIndex: 'cuit', key: 'cuit' },
          { title: 'Comentarios', dataIndex: 'comentarios', key: 'comentarios', width: 300, render: (record) =>
          <div >
              {record}
          </div> 
          },
          { title: 'Ip Datacenter', dataIndex: 'ip_datacenter', key: 'ip_datacenter', },
        ];
        return <Table columns={columns} dataSource={[record]} bordered scroll={{ x: true }} pagination={false} rowKey={(record) => `${record._id}`} />;
      };
    

    // Eliminar una Api
    const deleteApi = async(id) => {
        try {
        const resp = await fetchSinToken( `apis/${id}`, {}, 'DELETE' );
        const data = await resp.json();
        if(data.ok) {
            notification["success"]({
            message: data.message,
            });
        } else {
            notification["error"]({
            message: data.message,
            });
        }
        setReload(true);
        } catch (error) {
            console.log(error.message);
        }

    };



    return (
        <div>
            <Table
                columns={columns}
                scroll={{ x: true }}
                expandable={{ expandedRowRender: record => expandedRowRender(record) }}
                dataSource={data}
                rowKey={(record) => `${record._id}`}
            />
        </div>
    )
}
