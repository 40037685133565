import React, { useEffect, useState } from 'react';
import { Button, Input, Form, notification, PageHeader, InputNumber, Select, Checkbox } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import fetchSinToken from '../helpers/fetch';

const { Option } = Select;

const FormAdd = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  const  [form] = Form.useForm();
  const [ loading, setLoading ] = useState(false);


  useEffect(() => {
    const getIp = async() => {
      try {
        const resp = await fetchSinToken(`apis/${id}`);
        const body = await resp.json();
        if(body.ok)  form.setFieldsValue(body.api);
      } catch (error) {
        console.log(error.message);
      }
    }
    if(id) getIp();
  }, [id, form]);



  // Agregar una nueva Api
  const addIp = async(datos) => {

    if (datos.codigo === "" || datos.nombre.trim() === "" || datos.url.trim() === "") {
      notification["error"]({
          message: "Todos los campos son obligatorios."
        });
      return setLoading(false);
    }

    setLoading(true);

    if(id){ updateApi(datos); } 
    else { addApi(datos); }

  };


  const addApi = async( datos ) => {
    try {
      const resp = await fetchSinToken('apis', datos, 'POST');
      const body = await resp.json();
      if(body.ok){
        notification["success"]({
          message: body.message
        });
        setLoading(false);
        setTimeout(() => { navigate(`/`); }, 500);
      } else {
        notification["error"]({
          message: body.message
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }

  }



  const updateApi = async( datos ) => {
    try {
      const resp = await fetchSinToken(`apis/${id}`, datos, 'PUT');
      const body = await resp.json();
      if(body.ok){
        notification["success"]({
          message: body.message
        });
        setLoading(false);
        setTimeout(() => { navigate(`/`);  }, 500);
      } else {
        notification["error"]({
          message: body.message
        });
        setLoading(false);
      }    
    } catch (error) {
      console.log(error.message);
    }

  }



  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: "${label} es obligatorio",
  };

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          onBack={() => window.history.back()}
          title=""
          subTitle="Ir a la página principal"
        />
      </div>

      <div className="flex">
        <div className="form-add">
          <h2 className="form-title">{id ? "EDITAR API" : "NUEVA API"}</h2>
          <Form
            form={form}
            {...layout}
            name="form-api"
            onFinish={addIp}
            validateMessages={validateMessages}
            autoComplete="off"
          >
            <Form.Item
              name="codigo"
              label="Código Empresa"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "30%" }} />
            </Form.Item>
            <Form.Item
              name="nombre"
              label="Empresa"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="url"
              label="Dirección URL"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="puerto" label="Puerto">
              <InputNumber style={{ width: "30%" }} />
            </Form.Item>
            
            {id && (
              <Form.Item
                name="notificar"
                valuePropName="checked"
                label="¿Informar caída?"
              >
                <Checkbox />
              </Form.Item>
            )}

            <Form.Item name="email_licencias" label="Email Licencias">
              <Input />
            </Form.Item>
            <Form.Item name="cuit" label="Cuit">
              <Input />
            </Form.Item>
            <Form.Item name="ip_datacenter" label="Ip Datacenter">
              <Input />
            </Form.Item>
            <Form.Item name="lista_precios" label="Lista de Precios">
              <InputNumber style={{ width: "30%" }} />
            </Form.Item>
            <Form.Item name="uso_api" label="Uso API">
              <Select style={{ width: "100%" }}>
                <Option value={1}>Integraciones</Option>
                <Option value={2}>Wuala - PrestaShop</Option>
                <Option value={3}>Wuala - Mercado Libre</Option>
                <Option value={4}>App Soporte</Option>
                <Option value={5}>Integración E-Commerce Externo</Option>
                <Option value={6}>App ctacteweb</Option>
                <Option value={7}>Integracion soft. 3°</Option>
                <Option value={8}>Wuala - Server Propio Cliente</Option>
              </Select>
            </Form.Item>
            <Form.Item name="comentarios" label="Comentarios">
              <Input.TextArea />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 10, offset: 8 }}>
              <Button
                type="primary"
                block
                loading={loading}
                htmlType="submit"
                style={{ marginTop: "20px" }}
              >
                Confirmar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
 
export default FormAdd;