import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';


import 'antd/dist/antd.css';
import './index.css'

ReactDOM.render(
  <ConfigProvider locale={esES}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
);


